export default {
    MIN_VAL_DATASET: 1,
    MAX_VAL_DATASET: 100,
    DEFAULT_VAL_DATASET: 1,
    AVAILABLE_TYPES: [
        { shortType: 'p', longType: 'paragraph' },
        { shortType: 's', longType: 'sentence' },
        { shortType: 'ul', longType: 'unordered list' },
        { shortType: 'ol', longType: 'ordered list' },
        { shortType: 'w', longType: 'words' },
    ],
    DEFAULT_SELECTED_TYPE: {
        shortType: 'p',
        longType: 'paragraph'
    },
    KEY_SETTINGS: {
        ENABLED: true,
        ALT: true,
    },
    KEY_MAPPINGS: {
        P: 80,
        L: 76,
        W: 87,
        S: 83,
        O: 79,
        U: 85,
        X: 88,
        HELP: 191, // '?' '/' 
    },
    ALLOW_ADVANCED_FEATURES: false,

    TEXT_TRANSFORM_ENABLED: false,
    TEXT_TRANSFORM_OPTIONS: ['lower case', 'Upper Case', 'CAPITAL CASE']
}