import { copyText } from "@/utils/text"

var mixin = {
    methods: {
        copyText,
    
        throwError (msg=null){
            this.sendAlert ("error", msg || "Some error occured.")
        },
    
        sendAlert (type = "success", msg=null){
            if(!type || !msg) return
            alert (msg)
        },
    },
}

export default mixin