<template>
    <el-button
        @click="showDrawer = true" 
        type="primary" 
    >
        {{ $t('lipsum.help-popup.btn-text') }}
    </el-button>

    <el-drawer 
        title="Shortcuts" 
        v-model="showDrawer" 
        :with-header="true"
        append-to-body
        size="50%"
        show-close
    >
        <div class="container">
            <el-table
                :data="loremIpsumKeyShortcuts"
                stripe
                style="width: 100%"
            >
                <el-table-column
                    prop="key"
                    label="Key"
                    width="180"
                    class-name="shortcut"
                ></el-table-column>
                <el-table-column
                    prop="name"
                    label="Action"
                    width="250"
                ></el-table-column>
            </el-table>
        </div>
    </el-drawer>
</template>

<script>
import Settings from '@/settings/names'

export default {
    data() {
        let loremIpsumKeyShortcuts = Settings.KEY_SHORTCUTS

        return {
            showDrawer: false,
            loremIpsumKeyShortcuts,
        }
    },

    methods: {
        click(){
            this.showDrawer = !this.showDrawer
        },
    }
}
</script>