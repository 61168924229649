import _ from "lodash"

export const capitalize = string => _.capitalize(string)

export const copyText = (e, elId, cb) => {
    if (!elId) return
    const container = document.querySelector(`#${elId}`)
    if (!container) return
    
    const str = container.innerHTML
    function listener(e) {
        e.clipboardData.setData("text/html", str)
        e.clipboardData.setData("text/plain", str)
        e.preventDefault()
    }
    document.addEventListener("copy", listener)
    document.execCommand("copy")
    document.removeEventListener("copy", listener)

    if (cb) cb(e)
}
