<template>
    <div
        v-if="elements && elements.length > 0"
        class="placeholder"
        @dblclick="e => onDoubleClick(e)"
    >
        <transition-group name="fade" mode="out-in">
            <span
                v-for="(element, key) in elements"
                :key="key"
            >
                <ul v-if="element.shortType === 'ul'" class="list unordered-list">   
                    <li 
                        v-for="(i, k) in genArray(element.number)"
                        :key="k"
                    >
                        <lorem-ipsum class="generated-text" :add="`1s`" />
                    </li>
                </ul>
                <ol v-else-if="element.shortType === 'ol'" class="list ordered-list">   
                    <li 
                        v-for="(i, k) in genArray(element.number)"
                        :key="k"
                    >
                        <lorem-ipsum class="generated-text" :add="`1s`" />
                    </li>
                </ol>
                <span v-else class="text text-all">
                    <lorem-ipsum 
                        class="generated-text"
                        :add="`${element.number}${element.shortType}`"
                    />
                </span>
            </span>
        </transition-group>
    </div>
</template>

<script>
import LoremIpsum from './LoremIpsum.vue'
export default {
    props: {
        elements: {
            type: Array,
            default: () => {return []},
        },
        showAdvanced: {
            type: Boolean,
            default: false,
        },
        textTransform: {
            type: String
        }
    },
    components: {
        LoremIpsum,
    },

    methods: {
        onDoubleClick (e){
            this.$emit ('dblClick', e)
        },

        /** Generate array of length provided in @param number */
        genArray(number){
            number = Number(number)
            return [...Array(number).keys()]
        }
    },

}
</script>

<style>

</style>