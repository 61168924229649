<template>
    <div class="lorem-ipsum">
        <el-row align="middle">
            <el-col :span="20">
                <h1>{{ $t('page-title.lipsum-text') }}</h1>
            </el-col>
            <el-col :span="4">
                <div class="help-button">
                    <lorem-ipsum-help ref="lipsum-help" />
                </div>
            </el-col>
        </el-row>

        
        <div class="input-area">
            <el-row align="middle">
                <el-col :span="3">
                    <el-input 
                        type="number" 
                        :min="Settings.MIN_VAL_DATASET"
                        :max="maximumNum"
                        v-model="numberOfDataSets"
                        :placeholder="$t('lipsum.input-number-placeholder', [ selectedType.shortType ])" 
                        :disabled="maximumNum < 1"
                        width="100px"
                        show-word-limit
                    /> 
                </el-col>
                &nbsp;
                <el-col :span="4">
                    <el-select 
                        v-if="availableTypes && availableTypes.length > 0"
                        id="select-type"
                        v-model="selectedType.shortType"
                        :data-selected-long-type="selectedType.longType"
                        @change="e=>typeChanged(e)"
                    >
                        <el-option 
                            v-for="(type, key) in availableTypes"
                            :key="key"
                            :value="type.shortType"
                            :label="capitalize(type.longType)"   
                        ></el-option>
                    </el-select>
                </el-col>

                <el-col :span="4">
                    <span 
                        v-if="maximumNum < 50"
                        class="maximum remaining"
                        v-text="`${$t( 'lipsum.characters-remaining', [ maximumNum ] )}`"
                    ></span>
                </el-col>
            </el-row>
        </div>

        <div
            v-if="Settings.ALLOW_ADVANCED_FEATURES" 
            class="show-advanced-features"
        >
            <el-checkbox 
                v-model="showAdvanced"
                id="showAdvanced"
            />
            <label for="showAdvanced">{{ $t('lipsum.show-advanced-checkbox-label') }}</label>
        </div>

        <div v-if="showAdvanced" class="advanced-features">
            <div 
                v-if="Settings.TEXT_TRANSFORM_ENABLED 
                    && Settings.TEXT_TRANSFORM_OPTIONS 
                    && Settings.TEXT_TRANSFORM_OPTIONS.length > 0"
                class="advanced-text-transform-container"
            >
                <label for="advanced-text-transform">
                    {{ $t('lipsum.text-transform') }}
                </label>
                <select 
                    id="advanced-text-transform"
                    v-if="Settings.TEXT_TRANSFORM_ENABLED 
                        && Settings.TEXT_TRANSFORM_OPTIONS 
                        && Settings.TEXT_TRANSFORM_OPTIONS.length > 0"
                        v-model="textTransform"
                >
                    <!-- <option 
                        v-if="[textTransform].filter(Boolean).join() == ''" 
                        value=""
                        selected
                    >Select...</option> -->
                    <option 
                        v-for="(option, key) in Settings.TEXT_TRANSFORM_OPTIONS"
                        :key="key" 
                        :value="option"
                        v-text="option"
                    ></option>
                </select>
            </div>

        </div>
        
        <br />

        <div class="action-buttons">
            <el-button
                type="primary"
                @click="generate()"
                :disabled="maximumNum < 1"
            >{{ $t('lipsum.add') }}</el-button>
            <!-- <el-button
                v-if="maximumNum < 1"
                @click="refresh()"
            >${{ ('lipsum.refresh') }}</el-button> -->
            <el-button
                v-if="showCopyBtn || canCopy()"
                :type="copyBtnType"
                @click="e=>copyText(e, 'placeholder-container', handleCopyText)"
            >{{ $t('lipsum.copy') }}</el-button>
        </div>

        <transition name="fade" mode="out-in">
            <div 
                v-if="elements && elements.length > 0"
                class="placeholder-container"
                id="placeholder-container"
            >
                <placeholder
                    :elements="elements" 
                    :showAdvanced="showAdvanced"
                    :textTransform="textTransform"
                />
            </div>
        </transition>

        <!-- Back to top -->
        <el-backtop></el-backtop>
    </div>
</template>

<script>
import Placeholder from "@/components/Placeholder.vue"
import LoremIpsumHelp from "@/components/LoremIpsumHelp.vue"
import Settings from "@/settings/placeholder"
import { capitalize } from "@/utils/text"
import globalMixin from "@/mixins/global"

export default {
    components: { 
        Placeholder,
        LoremIpsumHelp,
    },

    mixins: [
        globalMixin,
    ],

    data (){
        const errors = {
            INVALID_INPUT_DATASET: this.$t('lipsum.errors.invalid-data-set')
        }
        return {
            Settings,
            errors,
            elements: [],
            numberOfDataSets: Settings.DEFAULT_VAL_DATASET,
            availableTypes: Settings.AVAILABLE_TYPES,
            selectedType: Settings.DEFAULT_SELECTED_TYPE,
            showHelpMenu: false,
            showAdvanced: false,
            textTransform: '',
            copyBtnType: 'default',
            showCopyBtn: false,
        }
    },

    methods: {
        capitalize,
        
        generate () {
            const { numberOfDataSets } = this
            if (!this.isValid(numberOfDataSets)) 
                return this.throwError (this.errors.INVALID_INPUT_DATASET)

            this.elements.push ({
                number: numberOfDataSets,
                shortType: this.selectedType.shortType,
                longType: this.selectedType.longType
            })

            this.showCopyBtn = true

            if (this.numberOfDataSets > this.maximumNum) 
                this.numberOfDataSets = this.maximumNum
            
        },

        typeChanged ({ target }) {
            if (!target) return
            // console.log(target) // todo
        },

        isValid (numberOfDataSets){
            if (numberOfDataSets < Settings.MIN_VAL_DATASET ||
                    numberOfDataSets > this.maximumNum
                ) return false
            return true
        },

        refresh(){
            window?.location?.reload()
            return
        },

        canCopy(){
            const container = document.querySelector("#placeholder-container")
            if (!container || Settings.MAX_VAL_DATASET < this.maximumNum) 
                return false
            return true
        },

        addTextByKeyMapping (e, key){
            e.preventDefault(); 

            if(key == 'x') this.refresh()
            else if (key == 'help') {
                this.showHelpMenu = true
                this.$refs['lipsum-help'].click()
            }

            this.numberOfDataSets = 1
            this.selectedType.shortType = key
            this.generate()
        },

        handleCopyText(e=null){
            if (e) {
                let prevText = e.target.textContent 
                setTimeout(()=>{
                    this.copyBtnType = 'default'
                    e.target.textContent = prevText
                }, 3000) // 3 seconds
                this.copyBtnType = 'success'
                e.target.textContent = this.$t('lipsum.copied')
            }
            this?.$message?.({
                message: this.$t('lipsum.copied-alert-text'),
                type: 'success'
            });
            // this.sendAlert('success', this.$t('lipsum.copied'))
        }
    },

    computed: {
        maximumNum(){
            let totalAdded = 0
            if(this.elements.length > 0){
                totalAdded = this?.elements
                    .map    (_el => _el.number)
                    .reduce ((total, current) => parseInt(total) + parseInt(current))
            }
            return Settings.MAX_VAL_DATASET - totalAdded
        }
    },

    mounted(){
        /** Handle key press events here */
        if(!Settings.KEY_SETTINGS.ENABLED) return
        const KEY = Settings.KEY_MAPPINGS
        const _this = this

        function handleKeyEvent(e) {
            let alt = true
            if(Settings.KEY_SETTINGS.ALT) alt = e.altKey

            if      (alt && e.keyCode == KEY.P) _this.addTextByKeyMapping(e, 'p')
            else if (alt && e.keyCode == KEY.W) _this.addTextByKeyMapping(e, 'w')
            else if (alt && e.keyCode == KEY.S) _this.addTextByKeyMapping(e, 's')
            else if (alt && e.keyCode == KEY.O) _this.addTextByKeyMapping(e, 'ol')
            else if (alt && e.keyCode == KEY.U) _this.addTextByKeyMapping(e, 'ul')
            else if (alt && e.keyCode == KEY.X) _this.addTextByKeyMapping(e, 'x')
            else if (alt && e.keyCode == KEY.HELP) _this.addTextByKeyMapping(e, 'help')
            
        }
        document.querySelector("body")
                .addEventListener("keyup", handleKeyEvent)
        /** key press events end here */
    }
}
</script>

<style>

</style>